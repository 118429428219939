export const webApplications = [
  {
    id: 1,
    title: "Dashboard ",
    website:"https://dashboardexample-2b732.web.app/",
    img:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRKvdHn8GmPGCO0y3SJqNHACygpm0h9VycMHg&usqp=CAU",
  },
  {
    id: 2,
    title: "E-commerce Web",
    website:"https://ecommerce-570b6.firebaseapp.com/",
    img:
      "https://cdn.dribbble.com/users/702789/screenshots/15054318/media/4ea5d492b7b07eebc9528ff960794879.png?compress=1&resize=1200x900",
  },
  {
    id: 3,
    title: "Gym Design",
    website:"https://typescriptwebsite.firebaseapp.com/",
    img:
      "https://cdn.dribbble.com/users/1387827/screenshots/15466426/media/deb2dca6762cd3610321c98bfccb0b72.png?compress=1&resize=1200x900",
  },
  {
    id: 4,
    title: "Relax Mobile App",
    website:"https://loginpage-a3d38.web.app/",
    img:
      "https://cdn.dribbble.com/users/4095861/screenshots/15467417/media/d6a15c416626f12b31fa5ca1db192572.png?compress=1&resize=1200x900",
  },
];



export const mobilePortfolio = [
  {
    id: 1,
    title: "Mobile Social Media",
    website:"https://ecommerce-570b6.firebaseapp.com/",
    img:
      "https://cdn.dribbble.com/users/2597268/screenshots/15468893/media/44313553d73ba41580f1df69749bba28.jpg?compress=1&resize=1200x900",
  },
  {
    id: 2,
    title: "Mobile  UI Design",
    website:"https://typescriptwebsite.firebaseapp.com/",
    img:
      "https://cdn.dribbble.com/users/5920881/screenshots/15463142/media/b5a460223798bd85d835710806e2f3dd.png?compress=1&resize=1200x900",
  },
  
];


